import React from "react";
import "./Loader.css";
const Loader = () => {
  return (
    <div className="loader-container">
      {/* <div className="spinner"></div> */}
      <div className="image-wrapper">
        <img
          src="https://cdn.dribbble.com/users/137715/screenshots/3984718/loading.gif"
          alt="loading"
          style={{
            width: "150px",
            height: "150px",
            objectFit: "cover",
            borderRadius: "50%",
          }}
        />
      </div>
    </div>
  );
};

export default Loader;
